import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import "./article.css"

const ARTICLEPAGE = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  return (
    <>
      <SEO seo={{ title: "404: Not found" }} global={strapiGlobal} />
      <Layout
        pageContext={{
          locale: defaultLocale,
          locales,
          defaultLocale,
          slug: "404",
        }}
        global={strapiGlobal}
      >
        <div className="header-container">
          <img
            className="header-image"
            src={"http://localhost:1337" + "/uploads/tesla_748432508a.png"}
          ></img>
          <span className="header-label">
            Tesla increases Model S price by another $5,000
          </span>
          <hr
            className="my-3 md:my-3 hr"
            style={{
              borderTop: "8px solid var(--evp-primary)",
              width: "46px",
            }}
          ></hr>
          <div className="sub-heading-container">
            <div className="sub-heading">
              <span>Rahul Sharma</span>
              <span>August 16, 2019</span>
            </div>
            <div className="icons">
              <span>
                <img
                  src={"http://localhost:1337" + "/uploads/fb_27269cafff.png"}
                ></img>
              </span>
              <span>
                <img
                  src={
                    "http://localhost:1337" + "/uploads/linkedin_0802bbeb06.png"
                  }
                ></img>
              </span>
              <span>
                <img
                  src={
                    "http://localhost:1337" + "/uploads/twitter_e232b035ca.png"
                  }
                ></img>
              </span>
            </div>
          </div>
          <div className="content-article">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one of the more
            obscure Latin words, consectetur, from a Lorem Ipsum passage, and
            going through the cites of the word in classical literature,
            discovered the undoubtable source. Lorem Ipsum comes from sections
            1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
            of Good and Evil) by Cicero, written in 45 BC. This book is a
            treatise on the theory of ethics, very popular during the
            Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
            amet..", comes from a line in section 1.10.32.
          </div>
          <div className="article-car-details">
            <img
              src={
                "http://localhost:1337" +
                "/uploads/tesla_details_295d40e3d8.png"
              }
            ></img>
            <span>
              Tesla has increased the price oof the Model S by another $5,000 -
              making it a $10,000 base price increase in less than a month
            </span>
          </div>

          <div className="article-final">
            <div>
              <span>Our take</span>
              <span>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32. The standard chunk of Lorem Ipsum used since
                the 1500s is reproduced below for those interested. Sections
                1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                Cicero are also reproduced in their exact original form,
                accompanied by English versions from the 1914 translation by H.
                Rackham. here are many variations of passages of Lorem Ipsum
                available, but the majority have suffered alteration in some
                form, by injected humour, or randomised words which don't look
                even slightly believable. If you are going to use a passage of
                Lorem Ipsum, you need to be sure there isn't anything
                embarrassing hidden in the middle of text. All the Lorem Ipsum
                generators on the Internet tend to repeat predefined chunks as
                necessary, making this the first true generator on the Internet.
                It uses a dictionary of over 200 Latin words, combined with a
                handful of model sentence structures, to generate Lorem Ipsum
                which looks reasonable. The generated Lorem Ipsum is therefore
                always free from repetition, injected humour, or
                non-characteristic words etc.
              </span>
            </div>
            <div className="article-final-ads">
              <span>Read Next</span>
              <img
                src={"http://localhost:1337" + "/uploads/ads_ccfefbfa79.png"}
              ></img>
              <span>
                Nissan slashes 2022 LEAF price to $27.5k, under $20k after Fed
                Tax credit
              </span>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ARTICLEPAGE

export const query = graphql`
  query NotFoundQuery4 {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
  }
`
